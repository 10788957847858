.card {

  width: 368.77px;
  padding: 20px;
  height: 368.77px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: linear-gradient(180deg,#0C1A24 15%, #255879);;
  border-radius: 44.4303px;
  gap: 15px;
  &_block{
    padding: 60px 0;
  }
  &_image {
    margin-top: 20px;
    width: max-content;
    height: 50px;
    object-fit: none;
  }
  &_content {
    padding: 15px;
  }
  &_title {
    margin-bottom: 40px;
    font-size: 1.2em;
    color: #ffffff;
  }
  &_list{
    &_full{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }
    &_item {
      text-align: left;
      line-height: 30px;
      font-size: 1em;
      color: #ffffff;
      list-style: disc;
    }
  }
}
