.advantages{
  padding: 50px 0;
  h2{
    font-size: 40px;
    text-transform: uppercase;
    padding-bottom: 50px;
    span{
      color: #DFA01F;
    }
  }
  button{
    display: block !important;
    margin: 20px auto !important;
  }
}

@media (max-width: 768px) {
  .advantages{
    h2{
      font-size: 20px;
    }
  }
}