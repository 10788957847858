.parallax_scene {
  position: relative;
  width: 100%;
  height: 100%;
  @media (max-width: 753px) {
    display: none;
  }
}

.laptop {
  position: relative!important;
  width: 100%!important;
  z-index: 1;
}

.icon {
  width: 50px;
  height: auto;
  position: absolute;
  transition: transform 0.1s ease-out;
  z-index: 2;

  &_dev {
    top: 45%;
    left: 10%;
    width: 57px;
  }

  &_js {
    top: 25%;
    right: 18%;
    width: 37px;
  }

  &_html {
    bottom: 25%;
    right: 17%;
    width: 83px;
  }

  &_css {
    bottom: 25%;
    left: 15%;
    width: 44px;
  }

  &_crm {
    bottom: 15%;
    left: 55%;
    width: 60px;
  }
  &_setting{
    top: 33%;
    right: 18%;
    width: 37px;
  }

  &.icon_brain {
    width: 87px;
    top: 60%;
    left: 35%;
  }
}
