.hero{
  padding: 90px 0 50px;

  &_block{
    display: block;
    position: relative;
    padding: 20px;
    margin-bottom: 200px;
  }
  &_title{
    font-weight: 700;
    font-size: 45px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #ffffff;
    span{
      color: #EAA313;
    }
  }

  p{
    padding: 15px 0;
  }

  .highlightedText {
    position: relative;
    padding: 20px;
    background: #0a0a0a; /* Цвет фона */
    color: #fff; /* Цвет текста */
  }

  .highlightedText h1 {
    color: #f3a832; /* Цвет заголовка */
  }

  .corners {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none; /* Чтобы элементы не перекрывали текст */
  }

  .corner {
    position: absolute;
    width: 20px; /* Длина линии */
    height: 20px;
    border: 2px solid #fff; /* Цвет обводки */
  }

  .topLeft {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
  }

  .topRight {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
  }

  .bottomLeft {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
  }

  .bottomRight {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
  }

  .midas_img{
    max-width: 600px;
    width: 100%;
    position: absolute;
    bottom: -120px;
    right: 50px;
  }
}



@media (max-width: 768px) {
  .hero{
    &_wrap{
      grid-template-columns: 1fr;
      align-items: center;
    }
    &_sub{
      width: 100%;
      margin: 10px 0;
      color: #ffffff;
    }
    .midas_img{
      max-width: 280px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}