.footer {
  background-color: #0f202d;
  color: #fff;
  padding: 20px 0;
  text-align: center;

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_logo {
    img {
      width: 60px;
      margin-bottom: 10px;
    }
  }

  &_nav {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;

    a {
      color: #fff;
      padding: 0;
      margin: 0;
      width: max-content;
      text-decoration: none;
      font-size: 1em;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_social {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;

    a {
      padding: 0;
      margin: 0;
      color: #fff;
      font-size: 1.2em;
      text-decoration: none;

      &:hover {
        color: #ddd;
      }
    }
  }

  &_copy {
    font-size: 0.8em;
    color: #a3b1c2;
  }
}