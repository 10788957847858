.card {
  position: relative;
  border-radius: 40px;
  padding: 20px;
  text-align: center;
  background: darkseagreen;
  height: 270px;
  background-image: linear-gradient(to top, #0f293b 0%, #25587A 100%);
  transition:0.5s all ease-in-out;
  @media (max-width: 753px) {
    height: 305px;
  }
  &:hover{
    transform: scale(1.1);
  }
  .card_title {
    text-transform: uppercase !important;
    font-size: 16px;
    font-weight: bold;
  }

  overflow: hidden;

  img {
    position: absolute;
    border-radius: 40px;
    right: 0;
    bottom: 0;
    max-width: 80%;
  }


}