.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  position: relative;
  width: 50%;
  height: auto;
  padding: 40px;
  color: #000000;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  p,h1{
    color: #000000;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.star {
  position: absolute;
  width: 50%;
  left: 0;
  top: 0;
  border-top-left-radius: 10px  ;
  height: 85%;
  background: #ffcc00;
  clip-path: polygon(33% 0, 0 33%, 0 0);
  font-size: 24px;
  &::before{
    content: "★";
    z-index: 2;
    font-size: 30px;
    top: 5px;
    left: 10px;
    position: absolute;
  }
}

.timer {
  font-size: 2rem;
  margin: 20px 0 40px 0;
  font-weight: bold;
}

.button {
  padding: 15px 25px;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
}

.button:hover {
  border: 1px solid silver
;
}

@media (max-width: 768px) {
  .popup {
    position: relative;
    width: 95%;
    height: auto;
    padding: 30px;
    color: #000000;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    p,h1{
      color: #000000;
    }
  }
}
