.service_item{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


@media (max-width: 768px) {
  .service_item{
    .item_title{
      font-size: 18px;
    }
    .item_text{
      font-size: 14px;
    }
  }
}