.hero{
    padding: 90px 0;
    &_wrap{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    &_img{
        width: 100%;
    }
    &_content{

    }
    &_title{
        font-weight: 900;
        font-size: 35px;
        line-height: 42px;
        text-transform: uppercase;
        color: #ffffff;
    }
    &_sub{
        width: 70%;
        margin: 30px 0;
        color: #ffffff;
    }
    .laptop_background {
        position: relative;
        width: 100%;
        max-width: 800px; /* Adjust as needed */
        background: url('../../../../images/notebook.png') no-repeat center center;
        background-size: contain;
        padding-top: 56.25%; /* This creates a 16:9 aspect ratio box, adjust as needed */
    }

    .laptop_video {
        position: absolute;
        top: 9%; /* Adjust based on your image */
        left: 16.5%; /* Adjust based on your image */
        width: 67.2%; /* Adjust based on your image */
        height: 76%; /* Adjust based on your image */
        object-fit: cover;
        border-radius: 1px;
    }
}

@media (max-width: 768px) {
    .hero{
        &_wrap{
            grid-template-columns: 1fr;
            gap: 25px;
            align-items: center;
        }
        &_sub{
            width: 100%;
            margin: 15px 0;
            color: #ffffff;
        }
    }
}