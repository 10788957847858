@font-face {
    font-family: 'Inter';
    src: local('Inter'),
    url('Inter-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'),
    url('Inter-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'),
    url('Inter-Black.ttf') format('truetype');
    font-weight: 900;
}

body{
    font-family: 'Inter', sans-serif;
}