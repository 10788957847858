.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  @media (max-width:753px) {
    padding: 0;
  }
}
.logo{
  width: 80px;
  margin-bottom: 50px;
}

.popup_content {
  background: #0C1A24;
  width: 100%;
  padding: 60px 20px;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  @media (max-width:753px) {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h2 {
    margin: 0;
  }

  p {
    margin: 10px 0;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #EAA313;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #bb830b;
    }
  }
}
