.logoSlider {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    background: linear-gradient(to right, #00141f 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
}

.logoSlideTrack {
  animation: logoScroll 5s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(250px * 14);
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.slide {
  display: flex;
  width: 250px;
  padding: 20px;
}

@keyframes logoScroll {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(calc(-1150px * 2));
  }
}
