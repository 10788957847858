$form-border-radius: 5px;
$button-bg-color: #fbbd00;
$button-text-color: #fff;
$input-border-color: #ccc;
$input-border-radius: 50px;
$input-padding: 15px 25px;
$input-margin: 5px 0;
$error-color: red;

.formContainer {
  border-radius: $form-border-radius;
  padding: 20px;
  width: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media (max-width: 753px) {
    width: 100%;
  }
  h2 {
    color: #ffffff;
    margin-bottom: 30px;
    font-size: 30px;
    @media (max-width: 753px) {
      font-size: 15px;
    }
  }
}

.grid50 {
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_block {
    padding: 60px 0;
  }

  &_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 753px) {
      flex-direction: column;
    }
  }
}

.formGroup {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input {
  flex: 1;
  width: 100%;
  height: min-content;
  padding: $input-padding;
  font-size: 16px;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
}

.textarea {
  width: 100%;
  padding: $input-padding;
  border: 1px solid $input-border-color;
  border-radius: 30px;
  min-height: 100px;
}

.button {
  background: $button-bg-color;
  color: $button-text-color;
  padding: 10px 20px;
  border: none;
  width: 100%;
  font-size: 25px;
  text-align: center;
  border-radius: $input-border-radius;
  cursor: pointer;
  margin-top: 10px;
}

.error {
  color: $error-color;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
  width: 100%;
  padding-left: 5px;
}

textarea { resize: none; }

.stickerContainer {
  width: 300px; /* Установите нужный размер */
  height: 300px; /* Установите нужный размер */
  position: relative;
  margin: 0 auto;
}

.sticker {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.stickerImage {
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.corner {
  content: '';
  position: absolute;
  width: 50px; /* Размер загнутого края */
  height: 50px; /* Размер загнутого края */
  background: inherit;
  top: 0;
  right: 0;
  background: white; /* Замените на цвет фона вашего стикера */
  transform-origin: top right;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
}

.sticker:hover .corner {
  transform: scale(1.5) rotate(45deg);
}

.sticker:hover .stickerImage {
  transform: translate(-10px, -10px);
}
