.link{
  color: white;
  padding: 15px 70px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  @media (max-width: 753px) {
    padding: 15px 55px;
  }
}