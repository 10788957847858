.feature_block{
  .feature_item{
    display: flex;
    gap: 50px;
    align-items: center;

    .feature_image{
      flex: 2;
    }

    .feature_info{
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 15px;
      h2{
        font-size: 40px;
        text-transform: uppercase;
      }
      h4{
        font-size: 25px;
      }
      p{
        padding: 10px 0;
      }
    }
  }

  .feature_item:nth-child(2n) {
    flex-direction: row-reverse;
  }

  .original {

  }

  .reverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .feature_block{
    padding: 25px 0;
    .feature_item{
      flex-direction: column-reverse;
      justify-content: center;
      gap: 25px;
      .feature_info{
        gap: 5px;
        h2{
          font-size: 20px;
          padding-bottom: 20px;
        }
        h4{
          font-size: 18px;
        }
        p{
          padding: 10px 0;
        }
      }
    }

    .feature_item:nth-child(2n) {
      flex-direction: column-reverse;
    }

    .reverse {
      flex-direction: column-reverse;
    }
  }
}