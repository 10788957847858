.dnkanim{
  display: flex;
  justify-content: center;
  padding: 190px 0;
  @media (max-width: 753px) {
    display: none;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.spiral {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  color: #e0ecef;
  font-family: "sans-serif";
}

@keyframes spiral {
  0% {
    --angle: 0deg;
  }
  100% {
    --angle: 360deg;
  }
}

.character {
  font-size: 2.8rem;
  color: white;
  text-transform: uppercase;
  transform: translateY(calc(sin(var(--angle)) * 100px)) scale(calc(cos(var(--angle)) * 0.5 + 0.5));
  animation: spiral 4s linear infinite;
}

@media (max-width: 490px) {
  .character {
    font-size: 2.2rem;
  }
}
