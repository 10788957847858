.wrapper{
  padding: 90px 0;
}
.fifty{
  width: 80%;
  margin: 30px auto;
  @media (max-width:753px) {
    width: 100%;
  }
}
.quote{
  color: #000;
  text-align: left;
  margin-bottom: 20px;

}
.name{
  color: #000;
  display: block;
  margin-bottom: 5px;
}
.pos{

  color: silver;
  &_wrap{
    align-items: center;
    text-align: left;
    display: flex;
    gap: 15px;
  }
}
.card {
  border-radius: 40px;
  padding: 40px;
  background: white;
  color: #000;
  text-align: center;
  max-width: 275px;
  margin: 0 10px;
  @media (max-width: 753px) {
    max-width: 85%;
  }
  img {
    max-width: 60px;

  }

  .card_main & {
    img {
      bottom: 0;
      right: 0;
    }
  }
}


