.faqItem {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.question {
  padding: 15px;
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.answer {
  background: #f9f9f9;
}
.open{
  padding: 15px;
}

.icon {
  font-size: 1.2rem;
}
