.hero{
    padding: 90px 0;
    @media (max-width: 753px) {
        padding: 30px 0;
    }
    &_wrap{
        display: flex;
        align-items: center;
        @media (max-width: 753px) {
            flex-direction: column;
        }
    }
    &_img{
        width: 100%;
    }
    &_content{
        margin-right: -25%;
        margin-top: -10%;
        @media (max-width: 753px) {
            margin: 0;
        }
    }
    &_title{
        font-weight: 900;
        font-size: 35px;
        line-height: 42px;
        text-transform: uppercase;
        color: #ffffff;
    }
    &_sub{
        width: 60%;
        margin: 30px 0;
        color: #ffffff;
        @media (max-width: 753px) {
            width: 100%;
        }
    }
}