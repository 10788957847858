.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
}

@media (min-width: 576px) {
    .container-sm, .container {
        max-width: 540px
    }
}
@media (min-width: 768px) {
    .container-md, .container-sm, .container {
        max-width: 720px
    }
}
@media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
        max-width: 960px
    }
}
@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px
    }
}
@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1320px
    }
}