.card{
  position: relative;
  border-radius: 40px;
  padding: 20px;
  text-align: center;
  background: darkseagreen;
  height: 270px;
  background-image: linear-gradient(to top, #0f293b 0%, #25587A 100%);
  img{
    position: absolute;
    max-width: 60px;
    bottom: 50px;
    right: 50px;
  }


}

@media (max-width: 768px) {
  .card{
    border-radius: 20px;
    padding:  10px;
    height: 160px;
    img{
      max-width: 45px;
      bottom: 25px;
      right: 25px;
    }

    h4{
      font-size: 18px;
    }
  }
}