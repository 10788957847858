.header{
    padding: 10px 0;
    display: flex;
    justify-content: center;
    gap: 10px;

}
 .languageSelect > *{
    position: absolute;
    top: 30px;
    right: 15%;
    background: none;
    color: #ffffff;
    border: none;
     @media (max-width:1070px) {
         right: 5%;
     }
     @media (max-width:800px) {
         right: 2%;
     }
     @media (max-width: 753px) {
         display: none;
     }
}

.logo{
    width: 85px;
    padding: 15px;
    border: 1px solid #ffffff;
    border-radius: 50px;
    @media (max-width: 753px) {
        display: none;
    }
}
.btn{
    @media (max-width: 753px) {
        display: none;
    }
}


.mainMenu{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}