body {
    background: url("../../images/main-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.App {
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: url("../../images/main-cursor.png"), auto;

}
.slick-next {
    right: -50px;
}
.slick-prev {
    left: -50px;
}
p, a, h1, h2, h3, h4, h5, h6 {
    color: #fff;
    cursor: url("../../images/hand-cursor.png"), pointer;
}

@media (max-width: 768px) {
    h1{
        font-size: 20px !important;
        line-height: 1.2 !important;
    }
    p{
        font-size: 14px;
    }
}