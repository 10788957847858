.count {
  font-size: 50px;

}

.wrapper {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0;
  @media (max-width: 753px) {
    flex-direction: column;
    gap: 30px;
  }
  h2{
    width: max-content;
  }
  h1{
    font-size: 16px;
  }
}