.projects{

  h2{
    font-size: 40px;
    font-weight: 900;
  }

  &_block{
    padding: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }

  .item{
    position: relative;
    overflow: hidden;
    background: #fff;
    color: #000;
    border-radius: 40px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 40px;
  }

  .share{
    position: absolute;
    background: #EAA313;
    width: 300px;
    height: 80px;
    padding: 20px;
    transform: rotate(-35deg);
    left: -80px;
    top: -22px;
    p{
      padding-top: 7px;
      font-weight: 700;
      color: #000;
      padding-right: 60px;
    }
  }

  .item_title{
    color: #000;
    font-size: 60px;
    font-weight: 900;
  }

  .item_text{
    color: #000;
    font-size: 14px;
  }

  .item_span{
    font-size: 14px;
    font-weight: 700;
  }

  a{
    color: #000 !important;
    display: inline-block;
    width: max-content;
  }
}


@media (max-width: 768px) {
  .projects{
    h2{
      font-size: 20px;
    }
    &_block{
      padding: 40px 0;
      display: grid;
      grid-template-columns: 1fr;
      gap: 25px;
    }

    .item{
      padding: 35px 25px 25px;
    }

    .item_title{
      font-size: 40px;
    }

    .share{
      height: 67px;
      top: -31px;
      p{
        padding-right: 74px;
      }
    }
  }
}