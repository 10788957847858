.card_block{
  padding: 50px 0;
}
.card_header{
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
}
.card_list_full{
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 753px) {
    display: flex;
    flex-direction: column;
  }
}
.mainbtn{
  display: block;
  margin: 40px auto!important;

}

@media (max-width: 768px) {
  .card_list_full{
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}