.services{
  padding: 50px 0;
  .services_block{
    padding: 35px;
    gap: 20px;
    row-gap: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    max-width: 70%;
    margin: 0 auto;
    color: #000;
    border-radius: 57px;

    h4{
      color: #000;
      font-size: 20px;
    }

    p{
      color: #000;
    }
  }
}

@media (max-width: 768px) {
  .services{
    .services_block{
      padding: 20px;
      gap: 20px;
      row-gap: 30px;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: space-between;

      max-width: 100%;
      margin: 0 auto;
      color: #000;
      border-radius: 30px;

      h4{
        color: #000;
        font-size: 20px;
      }

      p{
        color: #000;
      }
    }
  }
}