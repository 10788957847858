
.api_content{
  padding: 50px 30px 30px;
  border-radius: 40px;
  text-align: center;
  h3{
    font-size: 25px;
    font-weight: 900;
    text-transform: uppercase;
    padding-bottom: 35px;
  }
}

canvas {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .api_content{
    padding: 20px;
    border-radius: 20px;
    h3{
      font-size: 14px;
      font-weight: 900;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
  }
}
